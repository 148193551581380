import React from 'react'
import ContactFormWithCaptcha from '../modules/ContactFormWithCaptcha'
import { ParallaxLayer } from '@react-spring/parallax'
import { Box, Container, IconButton, Typography } from '@mui/material'
import { Domain, Facebook, YouTube } from '@mui/icons-material'

const Page5 = () => {

  const currentDate = new Date();
  const fullYear = currentDate.getFullYear();

  return (
    <>
        <Container id="contact-us">
          <ContactFormWithCaptcha />
        </Container>
        <Box sx={{bgcolor: "#f9f9f9", display: "flex", flex: 1, height: "100%", justifyContent: "center", p:1,}}>
          <Typography textAlign="center" fontSize={12} style={{mt:-10}}>© Cadsoft Corporation, All Rights Reserved. {fullYear}</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", mt:2.5, bgcolor: "adadad"}}>
          <IconButton href="https://www.facebook.com/CadsoftCorporation" target="_blank">
            <Facebook />
          </IconButton>
          <IconButton href="https://www.youtube.com/@Cadsoftcorp" target="_blank">
            <YouTube />
          </IconButton>
          <IconButton href="https://www.cadsoft.com/" target="_blank">
            <Domain />
          </IconButton>
        </Box>
    </>
)
}

export default Page5