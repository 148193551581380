import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const Page2 = (props) => {

  const fontSize = props?.windowSize.width < 768 ? 20 : 40;
  const lineHeight = props?.windowSize.width < 768 ? 1.25 : 1.5;
  const pagePadding = props?.windowSize.width < 768 ? 2 : 10;

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: 'white',
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        p: pagePadding,
        borderRadius: "0rem 20rem 0rem 0rem",
        overflowY: 'auto',
      }}
    >
      <Container>
        <Box sx={{display: "flex", flexWrap: "wrap",}}>
          <Typography sx={{display: "flex",}} component="p" fontSize={fontSize} fontWeight={500} lineHeight={lineHeight} variant="h2">
            Get everything you need to&nbsp;
          </Typography>
          <Typography sx={{ display: "flex",}} color="tertiary" component="p" fontSize={fontSize} fontWeight={500} lineHeight={lineHeight} variant="h2">
            streamline your sales process.&nbsp;
          </Typography>
          <Typography sx={{display: "flex",}} component="p" fontSize={fontSize} fontWeight={500} lineHeight={lineHeight} variant="h2">
            From front-end personalization, design and material estimates to back-end analytics. This comprehensive collaboration is designed to boost efficiency and provide you with powerful insights, helping you close sales faster.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Page2