import React from 'react';
import { Divider, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled Divider with arrow effect
const ArrowDivider = styled(Divider)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  '&::before, &::after': {
    content: '""',
    display: 'block',
    width: 0,
    height: 0,
    border: '10px solid transparent', // Adjust the size of the arrow here
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '&::before': {
    borderRight: `10px solid ${theme.palette.divider}`, // Left arrow
    left: 0,
  },
  '&::after': {
    borderLeft: `10px solid ${theme.palette.divider}`, // Right arrow
    right: 0,
  },
  '& span': {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.background.default,
  },
}));

const ArrowDividerWithText = ({ text }) => (
  <Box my={4}>
    <ArrowDivider>
      <Typography variant="body1">{text}</Typography>
    </ArrowDivider>
  </Box>
);

export default ArrowDividerWithText;
