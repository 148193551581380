import { Box, Container, Paper, Typography } from '@mui/material'
import React from 'react'
import GraphicRichInsights from '../../assets/svg/graphic-rich-insights.svg'

const ProcessFlow = (props) => {
  return (
    <Container>
      <Box>
        <Typography component="h2" variant="h2" fontWeight={600}>Complete sales cycle insights.</Typography>
        <Typography>Learn about your customers decision making habits and design preferences by integrating a design system that encourages purchasing while delivering product level data rich insights.</Typography>
        <Box sx={{display: "flex", justifyContent: "center", mt: 5,}}>
          <Box component="img" src={GraphicRichInsights} sx={{width: "80%", minWidth: 400,}}></Box>
        </Box>
      </Box>
    </Container>
  )
}

export default ProcessFlow