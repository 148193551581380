import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import ContactFormWithCaptcha from '../modules/ContactFormWithCaptcha';
import Page1 from '../submodules/Page1';
import Page2 from '../submodules/Page2';
import Page3 from '../submodules/Page3';
import { Box, Container, Typography } from '@mui/material';
import Page5 from '../submodules/Page5';
import MainGrid from '../modules/MainGrid';
import SessionsChart from '../modules/SessionsChart'
import DesignGraphic from '../modules/ProcessFlow';
import VennDiagram from '../modules/VennDiagram';
import ProcessFlow from '../modules/ProcessFlow';

const AppInterface = (props) => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Define a function to update the state with the current window size
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the state when the component mounts
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header />
      <Page1 windowSize={windowSize} />
      <Container>
        <MainGrid windowSize={windowSize} />
      </Container>
      <Page2 windowSize={windowSize} />
      <ProcessFlow windowSize={windowSize} />
      <Container>
        <Page3 windowSize={windowSize} />
      </Container>
      <Container>
        <Typography sx={{my:3, lineHeight: 1,}} component="h2" variant="h2" fontSize={windowSize.width < 768 ? 30 : 60 } fontWeight={600}>Improve engagement with an interactive design system focused to sell your products.</Typography>
        <SessionsChart />
      </Container>
      <Page5 />
    </>
  )
}

export default AppInterface