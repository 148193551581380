import React from 'react';
import { Box, Typography, List, ListItem, useMediaQuery, ListItemButton, ListItemText, IconButton } from '@mui/material';
import { Architecture, AutoGraph, Groups, Leaderboard, TipsAndUpdates, Workspaces } from '@mui/icons-material';
import LogomarkPersonalArchitectPrimary from '../../assets/svg/logomark-personal-architect-primary.svg';
import ArrowDividerWithText from '../submodules/ArrowDivider'

function VennDiagram(props) {
  const isTabletOrMobile = useMediaQuery('(max-width:900px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const circleSize = isMobile ? '50vw' : '35vw';
  const iconSize = props.windowSize.width < 900 ? "small" : "large";
  const textSize = props.windowSize.width < 900 ? 12 : 18;

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="relative"
        overflow="hidden"
      >
        {/* Circle 1 with List */}
        <Box
          position="absolute"
          borderRadius="50%"
          bgcolor="rgba(22, 60, 81, 0.15)"
          width={circleSize}
          height={circleSize}
          zIndex={1} // Assign lower zIndex for background elements
          top={isTabletOrMobile ? '20%' : '50%'}
          left={isTabletOrMobile ? '50%' : '15%'}
          style={{
            transform: isTabletOrMobile ? 'translate(-50%, -50%)' : 'translateY(-50%)',
            pointerEvents: 'none' // Prevent interference on this layer
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={2}
        >
          <List>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Front End" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, fontWeight: 600, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Personalization" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Design" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Material Lists" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/* Center Circle with Logo and surrounding Icons */}
        <Box
          position="absolute"
          borderRadius="50%"
          bgcolor="rgba(183, 28, 28, 0.1)"
          width={circleSize}
          height={circleSize}
          top="50%"
          left="50%"
          zIndex={2} // Higher zIndex to ensure this is clickable
          style={{
            transform: 'translate(-50%, -50%)',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          padding={2}
        >
          <Box
            alt="Logomark - Personal Architect"
            aria-label="Logomark"
            component="img"
            height={52}
            sx={{ marginTop: -5 }}
            src={LogomarkPersonalArchitectPrimary}
            width={40}
          />

          {/* Surrounding Icons arranged in a circular pattern */}
          <Box
            position="absolute"
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ transform: 'rotate(0deg)' }}
          >
            {/* Adjusted icon positions with higher zIndex and pointerEvents */}
            <IconButton sx={{ color: "#b71c1c", position: 'absolute', top: '25%', left: '10%', zIndex: 3 }} >
              <TipsAndUpdates fontSize={iconSize} />
            </IconButton>
            <IconButton sx={{ color: "#b71c1c", position: 'absolute', top: '10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3 }} >
              <Workspaces fontSize={iconSize} />
            </IconButton>
            <IconButton sx={{color: "#b71c1c", position: 'absolute', top: '25%', right: '10%', zIndex: 3}}>
              <Groups fontSize={iconSize} />
            </IconButton>
              <IconButton sx={{color: "#b71c1c", position: 'absolute', bottom: '25%', left: '10%', zIndex: 3 }}>
                <Architecture fontSize={iconSize} />
              </IconButton>
              <IconButton sx={{ color: "#b71c1c", position: 'absolute', bottom: '10%', left: '50%', transform: 'translate(-50%, 50%)', zIndex: 3 }} >
                <AutoGraph fontSize={iconSize}/>
              </IconButton>
            <IconButton sx={{  color: "#b71c1c", position: 'absolute', bottom: '25%', right: '10%', zIndex: 3, }} >
              <Leaderboard fontSize={iconSize} />
            </IconButton>
          </Box>

          <Typography
            fontWeight={600}
            fontSize={textSize}
            lineHeight={1}
            position="absolute"
            top="calc(50% + 40px)"
            textAlign="center"
          >
            Personal Architect
          </Typography>
        </Box>

        {/* Circle 2 with List */}
        <Box
          position="absolute"
          borderRadius="50%"
          bgcolor="rgba(145, 182, 33, 0.25)"
          width={circleSize}
          height={circleSize}
          zIndex={1} // Lower zIndex for background
          top={isTabletOrMobile ? '80%' : '50%'}
          right={isTabletOrMobile ? '50%' : '15%'}
          style={{
            transform: isTabletOrMobile ? 'translate(50%, -50%)' : 'translateY(-50%)',
            pointerEvents: 'none' // Allow click events to pass through this layer
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={2}
        >
          <List>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Back End" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, fontWeight: 600, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Rich Insights" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Product Analytics" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
            <ListItem dense>
              <ListItemButton>
                <ListItemText primary="Sales" primaryTypographyProps={{ textAlign: "center", fontSize: textSize, }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
      <ArrowDividerWithText />
    </>
  );
}

export default VennDiagram;
