import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import LogoLinesBlack from '../../assets/svg/logo-lines-black.svg'
import LogoPersonalArchitectPrimary from '../../assets/svg/logo-personal-architect-primary.svg'


const Page3 = (props) => {

  const minWidthSize = props?.windowSize.width > 768 ? 768 : 360;
  const fontSize = props?.windowSize.width < 768 ? 14 : 20;
  const headingSize = props?.windowSize.width < 768 ? 20 : 40;

  return (
      <Box sx={{display: "flex", flexWrap: "wrap", overflowY: 'auto',}}>
        <Box
          sx={{
            alignItems: "stetch",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{flex: 1,}}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: "#fefefe",
                borderTopColor: "primary.main",
                borderTopWidth: 20,
                borderTopStyle: "solid",
                flex: 1,
                m:1,
                minWidth: 360,
                minHeight: 320,
                px: 5,
                py: 2.5,
              }}
            >
              <Box>
                <Box sx={{alignItems: "center", borderBottomColor: "secondary.main", borderBottomWidth: 10, borderBottomStyle: "solid", borderBottomRightRadius: 10, display: "flex", height: 60, mb: 2.5, width: 130,}}>
                  <Box component="img" height={20} src={LogoLinesBlack} width={121} />
                </Box>
                <Typography fontFamily="Bebas Neue" fontSize={20}>
                  Personalized sales presentations made easy
                </Typography>
                <Typography>
                  Enhance your front-end sales process with data-driven insights that tailor each presentation to your customers needs. Capture key data to create personalized, impactful sales experiences that resonate and drive results.
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Box sx={{flex: 1,}}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: "#fefefe",
                borderTopColor: "tertiary.dark",
                borderTopWidth: 20,
                borderTopStyle: "solid",
                m:1,
                minWidth: 360,
                minHeight: 320,
                px: 5,
                py: 2.5,
              }}
            >
              <Box>
                <Box sx={{alignItems: "center", borderBottomColor: "tertiary.dark", borderBottomWidth: 10, borderBottomStyle: "solid", borderBottomRightRadius: 10, display: "flex", height: 60, mb: 2.5, width: 225,}}>
                  <Box component="img" height={40} src={LogoPersonalArchitectPrimary} width={220} />
                </Box>
                <Typography fontFamily="Bebas Neue" fontSize={20}>
                  Immersive 3D design experiences
                </Typography>
                <Typography>
                  Increase customer engagement and streamline the sales cycle using 3D design to facilitate selections. Accessibile from any device, this cloud-based platform keeps visitors on your site longer while guiding them smoothly toward their purchase decision.
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Box sx={{flex: 1,}}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: "#fefefe",
                borderTopColor: "secondary.main",
                borderTopWidth: 20,
                borderTopStyle: "solid",
                m:1,
                minWidth: minWidthSize,
                px: 5,
                py: 2.5,
              }}
            >
              <Box>
                <Typography fontFamily="Bebas Neue" fontSize={headingSize} lineHeight={1} textAlign="center">
                  Seamless Integration
                </Typography>
                <Box sx={{display: "flex", justifyContent: "center",}}>
                  <Divider sx={{backgroundColor: "secondary.main", my:1, width: '25%'}} />
                </Box>
                <Typography fontSize={fontSize}>
                  On the backend, Lines Group analyzes the material list Cadsoft passes back and creates sales and work orders to present to clients and installation teams. It reviews all sales to see where salespeople can improve and presents data in a dashboard.
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
  )
}

export default Page3